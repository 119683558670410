/*
 |-----------------------------------------------------------------------------
 | pages/_app.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { useEffect } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import dynamic from 'next/dynamic';
import markerSDK from '@marker.io/browser';

import TinaEditProvider from '@/tina/components/TinaDynamicProvider';
import { store } from '@/app/store';

import 'styles/global.css';

const Script = dynamic(() => import('next/script'));

const MyApp = ({ Component, pageProps }: AppProps) => {
	useEffect(() => {
		(async () => {
			await markerSDK.loadWidget({
				destination: process.env.NEXT_PUBLIC_MARKER_DESTINATION,
				customData: {
					environment: process.env.NEXT_PUBLIC_ENV,
				},
				// silent: true,
			});
		})();
	}, []);

	return (
		<>
			<Head>
				<meta charSet="UTF-8" />
				<meta
					name="viewport"
					content="width=device-width, initial-scale=1.0, shrink-to-fit=no"
				/>

				<link rel="preconnect" href="https://res.cloudinary.com" />
				<link rel="dns-prefetch" href="https://res.cloudinary.com" />

				{process.env.NEXT_PUBLIC_SNIPCART_API_KEY && (
					<>
						<link
							rel="preconnect"
							href="https://app.snipcart.com"
						/>
						<link
							rel="preconnect"
							href="https://cdn.snipcart.com"
						/>
						<link
							rel="stylesheet"
							href="https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.css"
						/>
					</>
				)}

				<link rel="icon" type="image/svg+xml" href="/favicon.svg" />
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>

				<link
					rel="icon"
					type="image/png"
					sizes="48x48"
					href="/favicon-48x48.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="192x192"
					href="/favicon-192x192.png"
				/>

				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="167x167"
					href="/favicon-167x167.png"
				/>
				<link
					rel="apple-touch-icon"
					type="image/png"
					sizes="180x180"
					href="/favicon-180x180.png"
				/>

				<link rel="author" href="/humans.txt" />
			</Head>
			{process.env.NEXT_PUBLIC_SNIPCART_API_KEY && (
				<Script src="https://cdn.snipcart.com/themes/v3.2.0/default/snipcart.js" />
			)}
			<Provider store={store}>
				<TinaEditProvider>
					<Component {...pageProps} />
				</TinaEditProvider>
			</Provider>
		</>
	);
};

export default MyApp;
