/*
 |-----------------------------------------------------------------------------
 | sentry.client.config.js
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | This file configures the initialization of Sentry on the browser. The config
 | you add here will be used whenever a page is visited.
 |
 */

import * as Sentry from '@sentry/nextjs';
import LogRocket from 'logrocket';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NEXT_PUBLIC_ENV !== 'development') {
	LogRocket.init(process.env.NEXT_PUBLIC_LOGROCKET_APP_ID);

	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://c8684c48f90440cdbbfee8d3c0a653fc@o358989.ingest.sentry.io/4253035',
		environment: process.env.NEXT_PUBLIC_ENV,
		tracesSampleRate: 1.0,

		beforeSend(e) {
			const logRocketSession = LogRocket.sessionURL;

			if (logRocketSession !== null) {
				e.extra['LogRocket'] = logRocketSession;
				return e;
			} else {
				return e;
			}
		},
	});
} else {
	Sentry.init({
		dsn:
			SENTRY_DSN ||
			'https://c8684c48f90440cdbbfee8d3c0a653fc@o358989.ingest.sentry.io/4253035',
		environment: process.env.NEXT_PUBLIC_ENV,
		tracesSampleRate: 1.0,
	});
}
